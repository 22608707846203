.content-form-input-container {
  display: block;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

.content-form-label {
  height: 1.5rem;
}

.content-form-input {
  border: 1px solid #ddd;
  display:block;
  width: 100%;
  height: 1.5rem;
}

.content-form-submit-container {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.content-form-submit-button {
  margin-top: 1rem;
  background-color: #5f5f5f;
  border: none;
  color: black;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.content-form-submit-button:hover {
  color: white;
}