/* articles styles */
.articles {
  padding: 1rem;
}

.articles > ul {
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 1fr));
  grid-gap: 1rem;
}

@media only screen and (min-width: 768px) {
  .articles > ul {
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    grid-gap: 1rem;
  }
}

@media only screen and (min-width: 450px) and (max-width: 768px) {
  .articles > ul {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    grid-gap: 1rem;
  }
}
