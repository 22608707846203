.landing-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.landing-page-container {
  max-width: 1150px;
}

.hr-container {
  margin: 3rem;
}

.solid-thin {
  border-top: 1px solid black;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
}

.pagination-container {
  width: 100;
  display: flex;
  justify-content: center;
}

.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.pagination > li {
  padding: 0.5rem;
}

.pagination > li > a {
  color: black;
  text-decoration: none;
}

.pagination > li > a:hover {
  color: #5f5f5f;
}