/* article-main styles */
.article-main {
  padding: 1rem;
}

.article-main > ul {
  display: grid;
  grid-template-columns: repeat(1, minmax(250px, 1fr));
  grid-gap: 1rem;
}

@media only screen and (min-width: 768px) {
  .article-main > ul {
    display: grid;
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    grid-gap: 1rem;
  }
}
