
.title {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-top: 1em;
  cursor: pointer;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.title-container> h1 {
  font-size: 50px;
  margin-bottom: 1rem;
}

.title-container > img {
  max-width: 100px;
  /* margin-left: 0.5rem; */
}