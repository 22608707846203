.article-view-img {
  width: 100%;
  max-height: 100%;
  padding-top: 0;
}

.article-view {
  align-items: center;
  text-align: center;
}

.article-view-content-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.article-link {
  padding-top: 2rem;
  font-size: 19px;
}

.article-highlight {
  padding-top: 2rem;
  font-size: 19px;
  color:#4682B4;
}

.article-highlight:hover {
  color:#ADD8E6;
}

.audio-player {
  padding-top: 2rem;
  width: 100%;
  height: 5rem;
}

.video-player {
  padding-top: 2rem;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .article-view-content {
    padding: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .article-view-content {
    padding: 2rem;
    max-width: 60%;
  }
}
