.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 15rem;
}

.login-label {
  width: 100%;
}

.login-label > input {
  width: 100%;
}

.login-submit-container {
  padding-top: 1rem;
}

.login-submit-button {
  background-color: #5f5f5f;
  border: none;
  color: black;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.submit-button:hover {
  color: white;
}