.article-form {
   display: inline-block;
   width: 500px;
}

.article-form-header-container {
  padding: 1rem;
}

.input-container {
  display: block;
  text-align: left;
  padding: 1rem;
  width: 100%;
}

.article-form-label {
  height: 1.5rem;
}

.article-form-input {
  border: 1px solid #ddd;
  display:block;
  width: 100%;
  height: 1.5rem;
}

.summary {
  height: 10rem;
  width: 100%;
  border: 1px solid #ddd;
  resize: none;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.is-main-container {
  padding-left: 1rem;
}

.is-main {
  text-align: left;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  margin: 0.5rem;
}

.current-file {
  padding-top: 1rem;
  padding-left: 1rem;
}

.current-file-link:hover {
  color: #5f5f5f;
}

.article-form-text-editor {
  width: 100%;
  padding: 1rem;
}

.file-upload-container {
  width: 100%;
  padding: 1rem;
}

.article-form-submit-container {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.submit-button-form {
  background-color: #5f5f5f;
  border: none;
  color: black;
  text-align: center;
  text-decoration: none;
  margin: 0.5rem;
  padding: 1rem;
  box-shadow: 5px 5px 5px;
}

.submit-button-form:hover {
  color: white;
}

.article-error {
  color: red;
  padding-top: 1rem;
  padding-left: 1rem;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
}

.file-upload-progress-container {
  display: flex;
  width: 100%;
  height: 2rem;
  border: 1px solid black;
}

.file-upload-progress {
  background-color: #5f5f5f;
}
