.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: block;
}

.modal-main {
  padding: 1.5rem;
  position:fixed;
  background: white;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  align-items: center;
  text-align: center;
  border: 1px solid black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.modal-line-container {
  padding-top: 1rem;
}

.modal-line {
  border-top: 1px solid black;
  padding-left: 1rem;
  padding-right: 1rem;
}