.social-media-container {
  display: flex;
  justify-content: flex-start;
  padding-left: 3rem;
  padding-top: 1rem;
}

.social-media-container > img {
  height: 2rem;
  cursor: pointer;
}

.header-button-container {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-div-2 {
  width: 34%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1000px) {
  .header-button-container {
    padding-top: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
  }

  .header-div-1 {
    width: 33%;
  }

  .header-div-2 {
    width: 34%;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .header-div-3 {
    width: 33%;
    display: flex;
    justify-content: flex-end;
  }
}

.link {
  text-decoration: none;
  color: #5f5f5f;
  padding-left: 1rem;
  padding-right: 1rem;
}

.header-hr-container {
  margin: 3rem;
}

.solid-thick {
  border-top: 3px solid black;
  width: 100%;
}

.search-container {
  padding-left: 1rem;
}

.search-container button {
  background: #ddd;
  border: none;
  cursor: pointer;
  color: #5f5f5f;
  padding-top: 0.1rem;
  padding-bottom: 0.15rem;
  display: none;
}

.search-container button:hover {
  background: #ccc;
}

.search-input {
  border: 1px solid #ddd;
  color: #5f5f5f;
  max-width: 10rem;
}

.search-input:focus {
  outline: none;
}

@media only screen and (max-width: 1000px) {
  .search-container {
    padding-top: 1.5rem;
  }
}