@import url(https://fonts.googleapis.com/css?family=Besley|Hina+Mincho:200,400);
body {
  margin: 0;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: indianred; */
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  padding-left: 20px;
  padding-right: 20px;
}

.article-summary  {
  list-style-type: none;
  cursor: pointer;
}

.article-summary  > div {
  max-height: 220px;
  overflow: hidden;
  position: relative;
}

.article-summary  > div > img {
  width: 100%;
  padding: 1rem .75rem;
}

.article-summary  > div > figcaption {
  position: absolute;
  bottom: 0;
  background-color: rgba(0,0,0,.7);
  width: 100%;
}

.article-summary  > h3 {
  padding: .75rem;
  font-size: 1.25rem;
}

.article-summary  > h4 {
  padding: .75rem;
  padding-bottom: 0;
  font-size: 1rem;
  font-weight: bold;

}

.article-summary  > p {
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem .75rem;
  color: #666666;
}

.article-summary-main  {
  list-style-type: none;
  cursor: pointer;

}

.article-summary-main  > div {
  max-height: 220px;
  overflow: hidden;
  position: relative;
}

.article-summary-main > div > img {
  width: 100%;
  padding: 1rem .75rem;
}

.article-summary-main > div > figcaption {
  position: absolute;
  bottom: 0;
  background-color: rgba(0,0,0,.7);
  width: 100%;
}

.article-summary-main > h3 {
  padding: .75rem;
  font-size: 1.25rem;
  padding-bottom: 0;
  padding-top: 0;
}

.article-summary-main > h4 {
  padding: .75rem;
  padding-top: 2rem;
  padding-bottom: 0;
  font-size: 1rem;
  font-weight: bold;
}

.article-summary-main > p {
  font-size: 1rem;
  line-height: 1.5;
  padding: 1rem .75rem;
  color: #666666;
}


/* articles styles */
.articles {
  padding: 1rem;
}

.articles > ul {
  display: grid;
  grid-template-columns: repeat(1, minmax(auto, 1fr));
  grid-gap: 1rem;
}

@media only screen and (min-width: 768px) {
  .articles > ul {
    display: grid;
    grid-template-columns: repeat(3, minmax(auto, 1fr));
    grid-gap: 1rem;
  }
}

@media only screen and (min-width: 450px) and (max-width: 768px) {
  .articles > ul {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
    grid-gap: 1rem;
  }
}

/* footer */
footer {
  background-color: #333;
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: white;
  text-align: center;
  font-size: .75rem;
  width: 100%;
}
/* article-main styles */
.article-main {
  padding: 1rem;
}

.article-main > ul {
  display: grid;
  grid-template-columns: repeat(1, minmax(250px, 1fr));
  grid-gap: 1rem;
}

@media only screen and (min-width: 768px) {
  .article-main > ul {
    display: grid;
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    grid-gap: 1rem;
  }
}


.title {
  display: flex;
  justify-content: center;
  align-items: baseline;
  padding-top: 1em;
  cursor: pointer;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.title-container> h1 {
  font-size: 50px;
  margin-bottom: 1rem;
}

.title-container > img {
  max-width: 100px;
  /* margin-left: 0.5rem; */
}
.social-media-container {
  display: flex;
  justify-content: flex-start;
  padding-left: 3rem;
  padding-top: 1rem;
}

.social-media-container > img {
  height: 2rem;
  cursor: pointer;
}

.header-button-container {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-div-2 {
  width: 34%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 1000px) {
  .header-button-container {
    padding-top: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
  }

  .header-div-1 {
    width: 33%;
  }

  .header-div-2 {
    width: 34%;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .header-div-3 {
    width: 33%;
    display: flex;
    justify-content: flex-end;
  }
}

.link {
  text-decoration: none;
  color: #5f5f5f;
  padding-left: 1rem;
  padding-right: 1rem;
}

.header-hr-container {
  margin: 3rem;
}

.solid-thick {
  border-top: 3px solid black;
  width: 100%;
}

.search-container {
  padding-left: 1rem;
}

.search-container button {
  background: #ddd;
  border: none;
  cursor: pointer;
  color: #5f5f5f;
  padding-top: 0.1rem;
  padding-bottom: 0.15rem;
  display: none;
}

.search-container button:hover {
  background: #ccc;
}

.search-input {
  border: 1px solid #ddd;
  color: #5f5f5f;
  max-width: 10rem;
}

.search-input:focus {
  outline: none;
}

@media only screen and (max-width: 1000px) {
  .search-container {
    padding-top: 1.5rem;
  }
}
.loading-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-container-small {
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-img {
  width: 5rem;
  height: 5rem;
}
.landing-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.landing-page-container {
  max-width: 1150px;
}

.hr-container {
  margin: 3rem;
}

.solid-thin {
  border-top: 1px solid black;
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
}

.pagination-container {
  width: 100;
  display: flex;
  justify-content: center;
}

.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.pagination > li {
  padding: 0.5rem;
}

.pagination > li > a {
  color: black;
  text-decoration: none;
}

.pagination > li > a:hover {
  color: #5f5f5f;
}
.about-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.article-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.article-container {
  width: 1150px;
  min-height: calc(100vh - 3.6rem);
}
.article-content {
  padding-top: 2em;
}

.DraftEditor-root {
  font-family: 'Hina Mincho', serif;
  /* width: 100%; */
}
.damnation-button {
  background-color: white;
  border: none;
  color: black;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.damnation-button:hover {
  color: #5f5f5f;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: block;
}

.modal-main {
  padding: 1.5rem;
  position:fixed;
  background: white;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  align-items: center;
  text-align: center;
  border: 1px solid black;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.modal-line-container {
  padding-top: 1rem;
}

.modal-line {
  border-top: 1px solid black;
  padding-left: 1rem;
  padding-right: 1rem;
}
.article-view-img {
  width: 100%;
  max-height: 100%;
  padding-top: 0;
}

.article-view {
  align-items: center;
  text-align: center;
}

.article-view-content-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.article-link {
  padding-top: 2rem;
  font-size: 19px;
}

.article-highlight {
  padding-top: 2rem;
  font-size: 19px;
  color:#4682B4;
}

.article-highlight:hover {
  color:#ADD8E6;
}

.audio-player {
  padding-top: 2rem;
  width: 100%;
  height: 5rem;
}

.video-player {
  padding-top: 2rem;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .article-view-content {
    padding: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .article-view-content {
    padding: 2rem;
    max-width: 60%;
  }
}

.create-article-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-label {
  text-align: left;
}

.editor-container {
  text-align: center;
}

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Hina Mincho', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
  max-width: 500px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}
input[type="file"] {
  display: none;
}

.custom-file-upload {
  background-color: #5f5f5f;
  border: none;
  color: black;
  padding: 0.5rem;
  text-decoration: none;
  display: inline-block;
  box-shadow: 5px 5px 5px;
}

.file-upload-component {
  padding-bottom: 2rem;
}

.custom-file-upload:hover {
  color: white;
}

.file-details {
  padding-top: 1rem;
  margin: 0;
}
.article-form {
   display: inline-block;
   width: 500px;
}

.article-form-header-container {
  padding: 1rem;
}

.input-container {
  display: block;
  text-align: left;
  padding: 1rem;
  width: 100%;
}

.article-form-label {
  height: 1.5rem;
}

.article-form-input {
  border: 1px solid #ddd;
  display:block;
  width: 100%;
  height: 1.5rem;
}

.summary {
  height: 10rem;
  width: 100%;
  border: 1px solid #ddd;
  resize: none;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.is-main-container {
  padding-left: 1rem;
}

.is-main {
  text-align: left;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  margin: 0.5rem;
}

.current-file {
  padding-top: 1rem;
  padding-left: 1rem;
}

.current-file-link:hover {
  color: #5f5f5f;
}

.article-form-text-editor {
  width: 100%;
  padding: 1rem;
}

.file-upload-container {
  width: 100%;
  padding: 1rem;
}

.article-form-submit-container {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.submit-button-form {
  background-color: #5f5f5f;
  border: none;
  color: black;
  text-align: center;
  text-decoration: none;
  margin: 0.5rem;
  padding: 1rem;
  box-shadow: 5px 5px 5px;
}

.submit-button-form:hover {
  color: white;
}

.article-error {
  color: red;
  padding-top: 1rem;
  padding-left: 1rem;
}

.file-upload-container {
  display: flex;
  flex-direction: column;
}

.file-upload-progress-container {
  display: flex;
  width: 100%;
  height: 2rem;
  border: 1px solid black;
}

.file-upload-progress {
  background-color: #5f5f5f;
}

.edit-article-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 15rem;
}

.login-label {
  width: 100%;
}

.login-label > input {
  width: 100%;
}

.login-submit-container {
  padding-top: 1rem;
}

.login-submit-button {
  background-color: #5f5f5f;
  border: none;
  color: black;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.submit-button:hover {
  color: white;
}
.copy-view {
  align-items: center;
  text-align: center;
}

.copy-content-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .copy-content {
    padding: 2rem;
  }
}

@media screen and (min-width: 768px) {
  .copy-content {
    padding: 2rem;
    max-width: 60%;
  }
}

.content-title {
  padding-left: 1rem;
  padding-right: 1rem;
}
.submissions-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.submissions-container {
  max-width: 1150px;
  min-height: calc(100vh - 3.6rem);
  padding-bottom: 3rem;
}
.archive-view {
  width: 1150px;
  align-items: center;
  text-align: center;
}

.link {
  text-decoration: none;
  color: #5f5f5f;
}

.archive-dates {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}
.archive-container {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 3.6rem);
}
.archive-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.archive-page-container {
  max-width: 1150px;
  min-height: calc(100vh - 3.6rem);
}

.pagination-container {
  width: 100;
  display: flex;
  justify-content: center;
}

.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.pagination > li {
  padding: 0.5rem;
}

.pagination > li > a {
  color: black;
  text-decoration: none;
}

.pagination > li > a:hover {
  color: #5f5f5f;
}
.edit-content-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.content-form-input-container {
  display: block;
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

.content-form-label {
  height: 1.5rem;
}

.content-form-input {
  border: 1px solid #ddd;
  display:block;
  width: 100%;
  height: 1.5rem;
}

.content-form-submit-container {
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.content-form-submit-button {
  margin-top: 1rem;
  background-color: #5f5f5f;
  border: none;
  color: black;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}

.content-form-submit-button:hover {
  color: white;
}
.search-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.search-page-container {
  max-width: 1150px;
  min-height: calc(100vh - 3.6rem);
}

.pagination-container {
  width: 100;
  display: flex;
  justify-content: center;
}

.pagination {
  margin-top: 0;
  margin-bottom: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.pagination > li {
  padding: 0.5rem;
}

.pagination > li > a {
  color: black;
  text-decoration: none;
}

.pagination > li > a:hover {
  color: #5f5f5f;
}
/* Typography imported from Google Fonts */
/* @import url('https://fonts.googleapis.com/css?family=Playfair+Display|Source+Sans+Pro:200,400'); */

body {
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Besley', serif;
}

p, a, button, input, textarea, label {
  font-family:  'Hina Mincho', serif;
}

/* Generic styles */
html {
  scroll-behavior: smooth;
}

a:hover {
  opacity: .6;
}
