.submissions-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.submissions-container {
  max-width: 1150px;
  min-height: calc(100vh - 3.6rem);
  padding-bottom: 3rem;
}