.archive-view {
  width: 1150px;
  align-items: center;
  text-align: center;
}

.link {
  text-decoration: none;
  color: #5f5f5f;
}

.archive-dates {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}